'use client';
import { useCallback, useEffect, useState } from 'react';
import { PermissionsTypes } from '../enums/permissions-types';
import { isAllowed } from '../utils/utils';
import { getSession } from 'next-auth/react';
import { Session } from 'next-auth';
import { usePathname } from 'next/navigation';

export const useIsAllowed = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [status, setStatus] = useState<string>('unauthenticated');
  const pathName = usePathname();

  const retrieveSession = useCallback(async () => {
    try {
      setStatus('loading');
      const sessionData = await getSession();

      if (sessionData) {
        setSession(sessionData);
        setStatus('authenticated');
        return;
      }

      setStatus('unauthenticated');
    } catch (error) {
      setStatus('unauthenticated');
      setSession(null);
    }
  }, []);

  useEffect(() => {
    retrieveSession();
    // use the pathname to force a re-render when the user navigates to a new page
  }, [retrieveSession, pathName]);

  const checkPermissions = useCallback(
    (permissions: PermissionsTypes[]) => {
      if (!session || !session.user.roles) {
        return false;
      }

      const roles = session.user.roles;

      if (roles && roles[0]) {
        return isAllowed(roles[0], permissions);
      } else {
        return false;
      }
    },
    [session, status]
  );

  return { checkPermissions, status, session };
};
