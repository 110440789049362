import axios from 'axios';
import { getCsrfToken, getSession } from 'next-auth/react';
import { toast, ToastTypeEnums } from './components/common';
import { AUTH_JWT_ACTIONS } from './common/constants/common';
import { QueryClient } from '@tanstack/react-query';
import { RoleTypes } from './lib/RBAC/enums/role-types';
import { signOut } from 'next-auth/react';
import dayjs from 'dayjs';

const CALLBACK_URL = `${process.env.NEXT_PUBLIC_LOGIN_URL}`;
const HOST_URL = `${process.env.NEXT_PUBLIC_HOST_URL}`;
const queryClient = new QueryClient();

const updateSession = async (action?: AUTH_JWT_ACTIONS, role?: RoleTypes) => {
  await fetch(`${HOST_URL}/api/auth/session`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      csrfToken: await getCsrfToken(),
      data: { action: action ? action : AUTH_JWT_ACTIONS.REFRESH, role },
    }),
  });
};

const axiosInterceptorInstance = () => {
  const instance = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
  });
  instance.interceptors.request.use(
    async (request) => {
      const session = await getSession();
      if (session) {
        request.headers['Authorization'] = `Bearer ${session.accessToken}`;
        request.headers['X-Time-Zone'] = dayjs.tz.guess();
      }
      return request;
    },
    (error) => {
      console.log(`%c DEBUG LOG: Handle request errors`, 'color: yellow');
      console.error(error);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const status = error.response ? error.response.status : 0;
      const msg =
        error.response && error.response.data && error.response.data.message ? error.response.data.message : null;
      switch (status) {
        case 403:
          toast({
            title: 'Warning',
            typeIcon: ToastTypeEnums.WARNING,
            description: msg || `You don't have such permissions`,
          });
          break;
        case 401:
          const session = await getSession();
          if (
            session &&
            session.user &&
            session.user.refreshTokenExpiresAt * 1000 > Date.now()
          ) {
            await updateSession();
          } else {
            console.log(`%c DEBUG LOG: REFRESH TOKEN IS EXPIRED, AUTO LOGOUT`, 'color: yellow');
            queryClient.clear();
            signOut({ callbackUrl: CALLBACK_URL });
          }
          break;
        case 422:
          // const currentRole =
          //   error.response && error.response.data && error.response.data.currentUserRole
          //     ? error.response.data.currentUserRole
          //     : null;
          // if (currentRole) {
          //   await updateSession(AUTH_JWT_ACTIONS.UPDATE_ROLE, currentRole);
          // } else {
          //   await updateSession(AUTH_JWT_ACTIONS.REFRESH);
          // }
          await updateSession(AUTH_JWT_ACTIONS.REFRESH);
          queryClient.invalidateQueries();
          break;
        case 400:
          let jsxElement = null;
          if (Array.isArray(msg)) {
            jsxElement = (
              <ul className="ml-3 list-disc">
                {msg.map((value, index) => (
                  <li key={`${value}_${index}`}>{value}</li>
                ))}
              </ul>
            );
          }
          toast({
            title: 'Warning',
            typeIcon: ToastTypeEnums.WARNING,
            description: jsxElement ? jsxElement : msg || `Validation error`,
          });
          break;
        case 404:
          toast({
            title: 'Warning',
            typeIcon: ToastTypeEnums.WARNING,
            description: msg || `Not Found`,
          });
          break;
        case 429:
          toast({
            title: 'Warning',
            typeIcon: ToastTypeEnums.WARNING,
            description: msg || `You have sent too many requests in a given amount of time`,
          });
          break;
        case 500:
          toast({
            title: 'Error',
            typeIcon: ToastTypeEnums.ERROR,
            description: msg || `Internal Server Error`,
          });
          break;
        case 503:
          toast({
            title: 'Error',
            typeIcon: ToastTypeEnums.ERROR,
            description: msg || `Service is temporarily unavailable`,
          });
          break;
        default:
          console.log(`%c DEBUG LOG: Unexpected error`, 'color: yellow');
          console.log('DEBUG LOG: error.response', error.response);
          break;
      }

      return Promise.reject(error.response ? error.response.data : error);
    }
  );

  return instance;
};

export default axiosInterceptorInstance();
