import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/common';
import { Input } from '@/components/form';

import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui';
import { ScrollArea } from '@/components/common';

import { cn } from '@/lib/utils';
import { getCountry, parseCountry } from './utils';
import { defaultCountries } from './data/countries';
import { UsePhoneInputConfig, usePhoneInput } from './hooks/usePhoneInput';
import { CountryData, CountryIso2, ParsedCountry } from './types';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

const SPRITE_HREF = '/images/spriteFlags.svg';

export interface PhoneInputProps extends Omit<UsePhoneInputConfig, 'onChange'> {
  className?: string;
  disabledSelect?: boolean;
  hasError?: boolean;
  placeholder?: string;
  onChange?: (
    phone: string,
    meta: {
      country: ParsedCountry;
      inputValue: string;
    }
  ) => void;
}

export type PhoneInputRefType =
  | null
  | (HTMLInputElement & {
      setCountry: ReturnType<typeof usePhoneInput>['setCountry'];
      state: {
        phone: string;
        inputValue: string;
        country: ParsedCountry;
      };
    });
const PhoneInput = forwardRef<PhoneInputRefType, PhoneInputProps>(function (
  { value, onChange, placeholder, disabledSelect = false, countries = defaultCountries, className, hasError, ...props },
  ref
) {
  const { phone, inputValue, inputRef, country, setCountry, handlePhoneValueChange } = usePhoneInput({
    value,
    countries,
    ...{},
    disableDialCodePrefill: true,
    onChange: (data) => {
      onChange?.(data.phone, {
        country: data.country,
        inputValue: data.inputValue,
      });
    },
  });

  useImperativeHandle<PhoneInputRefType, PhoneInputRefType>(ref, () => {
    if (!inputRef.current) return null;

    return Object.assign(inputRef.current, {
      // extend input ref with additional properties
      setCountry,
      state: {
        phone,
        inputValue,
        country,
      },
    });
  }, [inputRef, setCountry, phone, inputValue, country]);

  return (
    <div className={cn('flex', className)}>
      <CountrySelect
        hasError={hasError}
        disabled={disabledSelect}
        selectedCountry={country.iso2}
        countries={countries}
        onSelect={(country) => {
          setCountry(country.iso2);
        }}
      ></CountrySelect>
      <Input
        ref={ref}
        type="tel"
        placeholder={placeholder}
        onChange={handlePhoneValueChange}
        value={inputValue}
        hasError={hasError}
        className="rounded-e-lg rounded-s-none"
        {...props}
      />
    </div>
  );
});

PhoneInput.displayName = 'PhoneInput';

export { PhoneInput };

export interface CountrySelectorProps {
  selectedCountry: CountryIso2;
  onSelect: (country: ParsedCountry) => void;
  disabled?: boolean;
  hasError?: boolean;
  countries: CountryData[];
}

function CountrySelect({ disabled, onSelect, selectedCountry, hasError, countries }: CountrySelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const fullSelectedCountry = useMemo(() => {
    if (!selectedCountry) return undefined;
    return getCountry({
      value: selectedCountry,
      field: 'iso2',
      countries: countries,
    });
  }, [countries, selectedCountry]);

  return (
    <>
      {countries && countries.length > 1 ? (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <Button
              type="button"
              variant={'outline'}
              className={cn(
                'flex h-10 gap-1 rounded-e-none rounded-s-lg px-3',
                hasError && 'border-y-destructive border-l-destructive'
              )}
              disabled={disabled}
            >
              <FlagComponent country={fullSelectedCountry?.iso2.toUpperCase()} />
            </Button>
          </PopoverTrigger>
          <PopoverContent withoutPortal className="w-[300px] p-0">
            <Command>
              <CommandInput placeholder="Search country..." />
              <CommandList>
                <ScrollArea className="h-72">
                  <CommandEmpty>No country found.</CommandEmpty>
                  <CommandGroup>
                    {countries.map((c) => {
                      const country = parseCountry(c);
                      return (
                        <CommandItem
                          className="cursor-pointer gap-2"
                          key={country.iso2}
                          onSelect={() => {
                            onSelect(country);
                            setIsOpen(false);
                          }}
                        >
                          <FlagComponent country={country.iso2} />
                          <span className="flex-1 text-sm">{country.name}</span>
                          {country.iso2 && <span className="text-sm text-gray-500/80"> +{country.dialCode}</span>}
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                </ScrollArea>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      ) : (
        <Button
          type="button"
          variant={'outline'}
          className={cn(
            'flex h-10 gap-1 rounded-e-none rounded-s-lg px-3 cursor-default',
            hasError && 'border-y-destructive border-l-destructive'
          )}
        >
          <FlagComponent country={fullSelectedCountry?.iso2.toUpperCase()} />
        </Button>
      )}
    </>
  );
}

function FlagComponent({ country }: { country?: string }) {
  return (
    <span className="flex h-4 w-6 overflow-hidden rounded-[4px] bg-foreground/20">
      <svg>
        <use href={`${SPRITE_HREF}#${country?.toUpperCase()}`}></use>
      </svg>
    </span>
  );
}
