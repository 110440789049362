'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState, type ReactNode } from 'react';
import { useSession } from 'next-auth/react';
import { signOut } from 'next-auth/react';

type QueryApiClientProviderProps = {
  children: ReactNode;
};

const CALLBACK_URL = `${process.env.NEXT_PUBLIC_LOGIN_URL}`;

export function QueryApiClientProvider({ children }: QueryApiClientProviderProps) {
  const {data: session} = useSession();

  useEffect(() => {
    if (session && session.user.error === 'RefreshAccessTokenError') {
      console.log(`%c DEBUG LOG: REFRESH TOKEN ERROR. RUN AUTO LOGOUT`, 'color: yellow');
      signOut({ callbackUrl: CALLBACK_URL }); // Force sign in to hopefully resolve error
    }
  }, [session]);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retryDelay: 2000, // Will always wait 2000ms to retry, regardless of how many retries
            staleTime: 30000, // 30 seconds
            gcTime: 3 * (1000 * 60), // 3 mins
            retry: (failureCount, error: any) => {
              // Don't retry for certain error responses
              if (error?.statusCode === 400) {
                return false;
              }
              // Retry others just once
              return failureCount <= 1;
            },
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
